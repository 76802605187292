<template lang="pug">
  div
    div.basic100.py-1(dense flat)
      v-container(style="max-width: 600px")
        .h6.primary500--text Payment
    v-layout.my-6(v-if="isFetching")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    v-container.pa-4.mb-4(v-else style="max-width: 600px")
      v-card.pa-4
        div.p1.textColorDefault--text.text-center.my-6 Please transfer
          b  {{ balanceAmount | displayPrice($currency) }}
          span  to any of the following bank accounts:
        v-card.mb-8(flat outlined)
          v-simple-table.mb-2
            thead
              tr
                th.s2.basic400.pr-1(style="min-width: 120px") {{ $t('account_details') }}
                th.s2.basic400.px-1 {{ $t('bank') }}
                th.s2.basic400.pl-1.text-right(style="width: 52px") {{ $t('copy') }}

            tbody
              tr(v-for="(bank, index) in bankList" :key="bank.id")
                td.pr-1
                  div.p2.textColorDefault--text {{ bank.account_name }}
                  div.p2.textColorDefault--text {{ bank.bank_account }}
                td.px-1
                  div.p2.textColorDefault--text {{ bank.bank_name }}
                td.pl-1(align="right")
                  div
                    v-btn(icon small @click="clickCopyBankNo(bank.bank_account)" v-clipboard:copy="bank.bank_account")
                      eva-icon(name="copy-outline" width="18" height="18" fill="primary")
        v-btn.button-AA-medium.white--text.mb-2(color="info500" small height="40" block @click="clickSendPayment()") {{ $t('send_proof_of_payment') }}
      
      .button-Aa-small.info500--text.text-center.my-8.clickable(@click="clickSwitchPayment()") {{ $t('switch_to_another_payment_method') }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BankTransfer',
  data: () => ({
    isFetching: false,
    bankList: [],
    invoice: null
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead'
    }),
    balanceAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
    totalAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_amount
    },
    amountDue() {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
    minPaymentAmount() {
      if (!this.invoice.minimum_payment) return 0
      if (this.invoice.minimum_payment > this.amountDue) {
        return this.amountDue
      }
      return this.invoice.minimum_payment
    },
    paidAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_paid
    }
  },
  mounted () {
    this.fetchInvoice()
    this.$axios.get(`/leads/${this.lead.pid}/directpay/?count=100`)
      .then((response) => {
        response.data.results.forEach(bank => {
          if (bank.enabled) {
            this.bankList.push(bank)
          }
        })
      }).catch(() => {})
  },
  methods: {
    fetchInvoice () {
      this.isFetching = true
      this.$axios.get(`/invoices/${this.lead.pid}/`)
        .then((response) => {
          this.invoice = response.data
          this.$store.commit('current/updateOrderPrice', { ready: true, total: this.totalAmount, amountDue: this.amountDue, minPaymentAmount: this.minPaymentAmount, paidAmount: this.paidAmount })
        })
        .catch(() => {})
        .finally(() => {
          this.isFetching = false
        })
    },
    clickCopyBankNo (bankNo) {
      this.$store.commit('message/showMessage', ['success', `Copied: ${bankNo}`])
    },
    clickSendPayment () {
      let storeUrl = `${process.env.VUE_APP_YEZZA_CHECKOUT_URL}/orders/${this.lead.pid}`
      let message = `Hi, I have made the payment. Please check your bank account.%0d%0a%0d%0a${encodeURIComponent(`Order Form: ${storeUrl}`)}`
      location.href = `https://api.whatsapp.com/send?phone=${this.lead.member.phone}&text=${message}`
    },
    clickSwitchPayment () {
      if (this.$route.path.includes('orders')) {
        this.$router.push(`/orders/${this.lead.pid}/online_payment`)
        return
      }
      this.$router.push(`/checkout/${this.lead.pid}/`)
    }
  }
}
</script>

<style scoped>
</style>